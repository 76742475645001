import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Busway_Section from "../../Components/Main/Busway_Section";


export default function HomeBlog() {
    return (
        <>
            <Header />
            <Busway_Section/>
            <Footer />
        </>
    )
}